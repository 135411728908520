import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from './authpages/login';
import 'react-toastify/dist/ReactToastify.css';
import Playstore from './commoncomponents/playstore';
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <>
    <ToastContainer/>
    <Router>
      <Routes>
        <Route  path = '/signup' element={<Login/>}/>
        <Route  path = '/login' element={<Login/>}/>
        <Route path = '/success' element={<Playstore/>}/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
