import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk'; 
import userProfile from './reducer';

const rootReducer = combineReducers({
  userProfile,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
