import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import store from './commonjs/redux/store';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { ToastProvider } from './commonjs/toastcontext';

const root = document.getElementById('root');
const rootInstance = createRoot(root);
rootInstance.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastProvider>
      <App />
      </ToastProvider>
    </React.StrictMode>
  </Provider>
);  
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
