import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="c">
        <div className="row align_center border_bottom">
          <div className="col col_2">
            <Link to="/" className="logo">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="col col_2">
            <div className="social_icon">
              <Link to="#" aria-label="Facebook">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="#" aria-label="Twitter">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="#" aria-label="LinkedIn">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link to="#" aria-label="Instagram">
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="row footer_list">
          <div className="col col_3">
            <h3 className="tit_24">Explore</h3>
            <ul className="contect_li">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
          <div className="col col_3">
            <h3 className="tit_24">Utility Pages</h3>
            <ul className="contect_li">
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/terms-of-use">Terms of Use</Link></li>
            </ul>
          </div>
          <div className="col col_3">
            <h3 className="tit_24">Keep in Touch</h3>
            <ul className="contect_li contact_address">
              <li>
                <p className="w-25"><b>Address : </b></p>
                <p className="w-75">7607 E Harry St Wichita, KS 67207</p>
              </li>
              <li>
                <p className="w-25"><b>Mail : </b></p>
                <p className="w-75"><Link className="mt_0" to="mailto:info@rootsapp.co">info@rootsapp.co</Link></p>
              </li>
              <li>
                <p className="w-25"><b>Phone : </b></p>
                <p className="w-75"><Link className="mt_0" to="tel:+13166845122">+1 316-684-5122</Link></p>
              </li>
            </ul>
          </div>
        </div>
        <div class="copy_write">
            <p>© Copyright 2024 by Rootsapp. All rights reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
