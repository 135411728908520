import axios from 'axios';
const useCustomHeader = process.env.REACT_APP_USE_CUSTOM_HEADER === 'true';

function extractLoginUser() {
    const routePath = window.location.pathname;
    const regex = /\/u\/([^/]+)/;
  
    const match = routePath.match(regex);
    if (match) {
        return match[1].toString(); 
    } else {
        return 0;
    }
}

function updateAuthUserHeader(config) {
    if (!login_user) {
        login_user = extractLoginUser();
    }

    if (login_user) {
        config.headers['X-Authuser'] = login_user;
    }

    return config;
}

let login_user = null;

const axiosInstance = axios.create({
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        ...(useCustomHeader && {
            'Accept': 'application/json', 
            "X-Authuser": 0, 
            "X-Request-Agent": "APP", 
            "X-SID": "sid_polXybpvfKkBdCyAOTenFD", 
            "X-MUID": ""
        }),
    },
    withCredentials: true
});

axiosInstance.interceptors.request.use(
    config => updateAuthUserHeader(config),
    error => Promise.reject(error)
);


export default axiosInstance;
