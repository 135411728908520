function domain()
{
    return process.env.REACT_APP_API_URL;
}
export function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;
}
export function countryselectapi()
{
    return domain() + "/general/country/list/all";
}
export function country_codeapi()
{
    return domain() + "/general/mcc/list/all";
}
export function signupapi()
{
    return domain() + "/auth/signup";
}
export function sidapi()
{
    return domain() + "/auth/sid";
}
export function resendotpapi()
{
    return domain() + '/auth/resend-otp';
}
export function signupverifyapi()
{
    return domain() + '/auth/verify-otp';
}
export function loginapi()
{
   return domain() + "/auth/login";
}
export function profileapi()
{
    return domain() + '/user/profile'
}
export function logoutallapi()
{
    return domain() + '/auth/logout/all';
}