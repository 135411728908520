export const Error401 = () => {
    const lod4 = extractLoginUser();
    window.location.href = `/refresh?X-Authuser=${encodeURIComponent(lod4)}`;
    return lod4;
}
export const extractLoginUser = () => {
    const routePath = window.location.pathname;
    const regex = /\/u\/([^/]+)/; // Match the last part after the last slash
  
    const match = routePath.match(regex);
    if (match) {
        return match[1]; // Return the matched value
    }
    return 0; // Return null if no match is found
}
export const handleApiError = (error, setIsLoading = null, setError422 = null, handle422AsToast = true, showErrorToast) => {
  if (typeof showErrorToast !== 'function') {
    console.error('showErrorToast must be a function');
    return;
  }
 
  if (error.response) {
    const { status, data } = error.response;

    switch (status) {
      case 401:
        const errorCode = data.error_code;
        if (errorCode === 'token_expired' || errorCode === 'already_logged_out') {
          Error401(); // Handle unauthorized errors
        } else if(errorCode === 'session_error'){
          const current = window.location.pathname
          if(current === '/login' || current === '/signup'){
            return;
          }else{
          window.location.href = '/login'
          }
        }
         else {
          showErrorToast(data.mobile_error || 'Unauthorized access.', '401Error');
        }
        break;

      case 422:
        if (handle422AsToast) {
          showErrorToast(data.mobile_error || 'Validation error.', '422Error');
        } else if (typeof setError422 === 'function') {
          setError422(data.mobile_error || 'Validation error.');
        }
        break;

      case 500:
        showErrorToast(data.mobile_error || 'Internal server error.', '500Error');
        break;

      default:
        showErrorToast('An unexpected error occurred.', 'UnexpectedError');
        break;
    }
  } else {
    showErrorToast('Network error or server is unreachable.', 'NetworkError');
  }

  if (typeof setIsLoading === 'function') {
    setIsLoading(false);
  }
};
export const getMaskedEmail = (email) => {
  if (!email) {
    return null;
  }
  const [username, domain] = email.split('@');
  const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 1);  
  return `${maskedUsername}@${domain}`;
};
export const getQueryParameter = (paramName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}

function redirectTo(url, params) {
  const queryString = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
  const redirectUrl = `${url}?${queryString}`;
  window.location.href = redirectUrl;
}

// Define the redirectToWithAuthUser function
export const redirectToWithAuthUser = (redirectUrl, X_AuthUser) => {
  const queryParams = {
    X_AuthUser: X_AuthUser
  };
  redirectTo(redirectUrl, queryParams);
}

