import { profileapi, publicDomain } from "../url";
import axiosInstance from "../axiosinstand";
import { getQueryParameter, handleApiError } from "../commonfunctions";
const publicUrl = publicDomain();
const profileUrl = profileapi();
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const fetchUserProfileRequest = () => ({
    type: FETCH_USER_PROFILE_REQUEST,
    });
  
    export const fetchUserProfileSuccess = (userProfile) => ({
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: userProfile,
    });
  
    export const fetchUserProfileFailure = (error) => ({
    type: FETCH_USER_PROFILE_FAILURE,
    payload: error,
    });
    export const fetchUserProfile = (showErrorToast) => {
        return async (dispatch) => {
          dispatch(fetchUserProfileRequest()); 
          try {  
            const response = await axiosInstance.get(profileUrl);
         
            if (response.status === 200) {
              const data = response.data.data;
              const current = window.location.pathname;
              if(current === '/login' || current === '/signup'){
                const redirecturl = getQueryParameter('redirecturl')
                if(redirecturl){
                    window.location.href = redirecturl
                }else{
                 window.location.href = '/success'
                }    
                
              }
              dispatch(fetchUserProfileSuccess({
                is_default : data.image_status,
                image: `${publicUrl}core/${data.image}`,
                default_profile:`${publicUrl}/core/${data.default_profile}`,
                name: data.name,
                dob: data.dob,
                gender: data.gender,
                email: data.email,
                code:data.mcc.code,
                mobile: data.mobile,
                mcc_id: data.mcc_id,
                mcc_countrycode: data.mcc.country_code,
                dob_visibility : data.dob_visibility,
                profile_image_visibility:data.profile_image_visibility,
                gender_visibility:data.gender_visibility
              }));
            }
          } catch (error) {
            handleApiError(error, null, null, true, showErrorToast);
            dispatch(fetchUserProfileFailure(error));
            }
        };
      };